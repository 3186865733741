import { useState } from "react";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Tooltip,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box } from "@mui/material";
import { tokens } from "../../theme/theme";
import "./sideBar.scss";
import { NavLink } from "react-router-dom";

export const SideBar = ({
  openMenu,
  setOpenMenu,
}: {
  openMenu: boolean;
  setOpenMenu: (element: boolean) => void;
}) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const matches = useMediaQuery("(max-width:768px)");

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <Box
      sx={{
        backgroundColor: colors.bgColor.DEFAULT,
        borderRight: `1px solid ${colors.borderColor.DEFAULT}`,
      }}
      className={`sideBar ${!openMenu && "close"}`}
    >
      <Box className="logo">
        <Typography component="span">
          {matches === true ? "Alex" : openMenu === true ? "Alex" : "A"}
        </Typography>
        <Typography component="span" color="#13e768">
          {matches === true ? "Merf" : openMenu === true ? "Merf" : "M"}
        </Typography>
      </Box>
      <Box sx={{ flex: 1 }}>
        <List
          sx={{
            backgroundColor: colors.bgColor.DEFAULT,
          }}
          aria-labelledby="list-files"
          subheader={
            <ListSubheader
              component="div"
              id="list-files"
              sx={{
                backgroundColor: colors.bgColor.DEFAULT,
              }}
            >
              Файлы
            </ListSubheader>
          }
        >
          <Tooltip
            title="Резюме"
            arrow
            placement="right"
            disableHoverListener={openMenu}
          >
            <ListItemButton
              component="a"
              href="../../files/resume.pdf"
              download
            >
              <ListItemText primary="Резюме" />
            </ListItemButton>
          </Tooltip>
        </List>
        <List
          sx={{
            backgroundColor: colors.bgColor.DEFAULT,
          }}
          aria-labelledby="list-links"
          subheader={
            <ListSubheader
              component="div"
              id="list-links"
              sx={{
                backgroundColor: colors.bgColor.DEFAULT,
              }}
            >
              Ссылки
            </ListSubheader>
          }
        >
          <Tooltip
            title="Emails Open"
            arrow
            placement="right"
            disableHoverListener={openMenu}
          >
            <ListItemButton onClick={handleClick}>
              <ListItemText primary="Email" />
              {matches === true
                ? !openMenu && (open ? <ExpandLess /> : <ExpandMore />)
                : openMenu && (open ? <ExpandLess /> : <ExpandMore />)}
            </ListItemButton>
          </Tooltip>

          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Tooltip
                title="Mail.ru"
                arrow
                placement="right"
                disableHoverListener={openMenu}
              >
                <ListItemButton
                  sx={{ pl: 4 }}
                  component="a"
                  href="mailto:markus.awp@mail.ru"
                >
                  <ListItemText primary="Mail.ru" />
                </ListItemButton>
              </Tooltip>
              <Tooltip
                title="Яндекс"
                arrow
                placement="right"
                disableHoverListener={openMenu}
              >
                <ListItemButton
                  sx={{ pl: 4 }}
                  component="a"
                  href="mailto:alexmerf2013@yandex.ru"
                >
                  <ListItemText primary="Яндекс" />
                </ListItemButton>
              </Tooltip>
            </List>
          </Collapse>
          <Tooltip
            title="Telegram"
            arrow
            placement="right"
            disableHoverListener={openMenu}
          >
            <ListItemButton component="a" href="https://telegram.me/AlexMerf">
              <ListItemText primary="Telegram" />
            </ListItemButton>
          </Tooltip>
          <Tooltip
            title="WhatsApp"
            arrow
            placement="right"
            disableHoverListener={openMenu}
          >
            <ListItemButton component="a" href="https://wa.me/79144423824">
              <ListItemText primary="WhatsApp" />
            </ListItemButton>
          </Tooltip>
          <Tooltip
            title="GitHub"
            arrow
            placement="right"
            disableHoverListener={openMenu}
          >
            <ListItemButton
              component="a"
              href="https://github.com/AlexMerf?tab=repositories"
            >
              <ListItemText primary="GitHub" />
            </ListItemButton>
          </Tooltip>
          <Tooltip
            title="Instagram"
            arrow
            placement="right"
            disableHoverListener={openMenu}
          >
            <ListItemButton
              component="a"
              href="https://www.instagram.com/aleksandrbuintsev"
            >
              <ListItemText primary="Instagram" />
            </ListItemButton>
          </Tooltip>
        </List>
        {matches === true && (
          <List
            sx={{
              backgroundColor: colors.bgColor.DEFAULT,
            }}
            aria-labelledby="list-files"
            subheader={
              <ListSubheader
                component="div"
                id="list-files"
                sx={{
                  backgroundColor: colors.bgColor.DEFAULT,
                }}
              >
                Nav Link
              </ListSubheader>
            }
          >
            <Tooltip
              title="Резюме"
              arrow
              placement="right"
              disableHoverListener={openMenu}
            >
              <ListItemButton
                component={NavLink}
                to="/"
                onClick={() => setOpenMenu(true)}
              >
                <ListItemText primary="Home" />
              </ListItemButton>
            </Tooltip>
            <Tooltip
              title="Projects"
              arrow
              placement="right"
              disableHoverListener={openMenu}
            >
              <ListItemButton
                component={NavLink}
                to="projects"
                onClick={() => setOpenMenu(true)}
              >
                <ListItemText primary="Projects" />
              </ListItemButton>
            </Tooltip>
            <Tooltip
              title="Contacts"
              arrow
              placement="right"
              disableHoverListener={openMenu}
            >
              <ListItemButton
                component={NavLink}
                to="contacts"
                onClick={() => setOpenMenu(true)}
              >
                <ListItemText primary="Contacts" />
              </ListItemButton>
            </Tooltip>
          </List>
        )}
      </Box>
    </Box>
  );
};
