import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  Box,
  Typography,
  Link,
  useTheme,
  IconButton,
  Tooltip,
} from "@mui/material";
import { ContentCopy } from "@mui/icons-material";
import { tokens } from "../../theme/theme";
import "./ContactsList.scss";

const listContacts = [
  {
    id: 1,
    link: "tel:79144423824",
    name: "Телефон",
    label: "+7 914 442 38 24",
    copy: "+79144423824",
  },
  {
    id: 2,
    link: "mailto:markus.awp@mail.ru",
    name: "E-mail",
    label: "MARKUS.AWP@MAIL.RU",
    copy: "markus.awp@mail.ru",
  },
  {
    id: 3,
    link: "mailto:alexmerf2013@yandex.ru",
    name: "Mail.yandex",
    label: "ALEXMERF2013@YANDEX.RU",
    copy: "alexmerf2013@yandex.ru",
  },
  {
    id: 4,
    link: "https://telegram.me/AlexMerf",
    name: "Telegram",
    label: "@ALEXMERF",
    copy: "@AlexMerf",
  },
  {
    id: 5,
    link: "https://wa.me/79144423824",
    name: "WhatsAPP",
    label: "+7 914 442 38 24",
    copy: "+79144423824",
  },
  {
    id: 6,
    link: "https://vk.com/alexmerf",
    name: "VK",
    label: "VK.COM/ALEXMERF",
    copy: "https://vk.com/alexmerf",
  },
  {
    id: 7,
    link: "https://github.com/AlexMerf?tab=repositories",
    name: "GITHUB",
    label: "GITHUB.COM/ALEXMERF",
    copy: "https://github.com/AlexMerf?tab=repositories",
  },
  {
    id: 8,
    link: "https://www.instagram.com/aleksandrbuintsev",
    name: "Instagram",
    label: "ALEKSANDRBUINTSEV",
    copy: "https://www.instagram.com/aleksandrbuintsev",
  },
];

export const ContactsList = () => {
  const [copy, setCopy] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  function copyBtnHandel() {
    setCopy(true);
  }

  function copyLeaveChange() {
    setTimeout(() => {
      setCopy(false);
    }, 1000);
  }

  return (
    <Box className="contactsList">
      {listContacts.map((obj) => (
        <Box
          key={obj.id}
          className="contacts__item"
          sx={{ border: `1px solid ${colors.borderColor.DEFAULT}` }}
        >
          <Tooltip title={copy === true ? "Copied!" : "Copy"} arrow>
            <IconButton
              aria-label="copy"
              className="copy__contact"
              size="small"
              onClick={(e) => copyBtnHandel()}
              onMouseLeave={() => copyLeaveChange()}
            >
              <CopyToClipboard text={obj.copy}>
                <ContentCopy fontSize="small" />
              </CopyToClipboard>
            </IconButton>
          </Tooltip>

          <Typography component="span">{obj.name}</Typography>
          <Link href={obj.link} className="link">
            {obj.label}
          </Link>
        </Box>
      ))}
    </Box>
  );
};
