import { Box, Skeleton, useTheme } from "@mui/material";
import { tokens } from "../../theme/theme";

export const Sceleton = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box sx={{ p: "10px 0px" }}>
      <Skeleton
        sx={{ backgroundColor: colors.bgColor.DEFAULT }}
        variant="rectangular"
        width="100%"
        height="300px"
      />
    </Box>
  );
};
