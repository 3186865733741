import {
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  useTheme,
} from "@mui/material";
import { tokens } from "../../theme/theme";
import "./stack.scss";

const listStack = [
  { id: 1, label: "HTML5, CSS3, JavaScript (ES6+)" },
  { id: 2, label: "Gulp" },
  { id: 3, label: "SASS / CSS Modules" },
  { id: 4, label: "ReactJS" },
  { id: 5, label: "ModX" },
  { id: 6, label: "Git / GitHub" },
];

export const Stack = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <List
      subheader={
        <ListSubheader
          component="div"
          sx={{ background: colors.bgColor.DEFAULT }}
        >
          Stack-технологии:
        </ListSubheader>
      }
      className="list__stack"
      sx={{ background: colors.bgColor.DEFAULT }}
    >
      {listStack.map((obj) => (
        <ListItem key={obj.id} disablePadding className="list__stack-item">
          <ListItemText
            primary={obj.label}
            sx={{ color: colors.primery.DEFAULT }}
          />
        </ListItem>
      ))}
    </List>
  );
};
