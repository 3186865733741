import { Box } from "@mui/material";
import { Card } from "../../components/card";
import { Stack } from "../../components/stack";
import "./home.scss"

export const Home = () => {
  return (
    <Box className="wrapperHome">
      <Card />
      <Stack />
    </Box>
  );
};
