import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material";

const direction = sessionStorage.getItem("direction")?.split('"')[1];

export const tokens = (mode: string) => ({
  ...(mode === "dark"
    ? {
        shadowBg: {
          DEFAULT: "rgb(255 255 255 / 25%)",
        },
        background: {
          DEFAULT: "#262626",
        },
        borderColor: {
          DEFAULT: "rgba(184, 184, 184, 0.2);",
        },
        menuBgColor: {
          DEFAULT: "#000000",
        },
        bgColor: {
          DEFAULT: "#3A3A3A",
          50: "#828282",
          100: "#787878",
          200: "#636363",
          300: "#4F4F4F",
          400: "#3A3A3A",
          500: "#262626",
          600: "#0A0A0A",
          700: "#000000",
          800: "#000000",
          900: "#000000",
        },
        globalBgColor: {
          DEFAULT: "#2e2e2e",
        },
        colorBgSecondary: {
          DEFAULT: "#303337",
        },
        gray: {
          DEFAULT: "#9DA3AA",
          50: "#303337",
          100: "#4A4F55",
          200: "#646B73",
          300: "#7F8790",
          400: "#9DA3AA",
          500: "#B3B8BD",
          600: "#C9CCD0",
          700: "#DFE1E3",
          800: "#F5F5F5",
          900: "#FFFFFF",
        },

        primery: {
          DEFAULT: "#13e768",
          50: "#FDE9D7",
          100: "#FCDEC4",
          200: "#F9C99D",
          300: "#F7B476",
          400: "#F59F50",
          500: "#F38A29",
          600: "#D86E0C",
          700: "#A35309",
          800: "#6E3806",
          900: "#381D03",
        },
      }
    : {
        shadowBg: {
          DEFAULT: "rgb(0 0 0 / 25%)",
        },
        background: {
          DEFAULT: "#F8F8F8",
        },
        borderColor: {
          DEFAULT: "rgba(157, 163, 170, 0.2)",
        },
        menuBgColor: {
          DEFAULT: "#1c2b36",
        },
        bgColor: {
          DEFAULT: "#FFFFFF",
          50: "#FFFFFF",
          100: "#FFFFFF",
          200: "#FFFFFF",
          300: "#FFFFFF",
          400: "#FFFFFF",
          500: "#FFFFFF",
          600: "#E3E3E3",
          700: "#C7C7C7",
          800: "#ABABAB",
          900: "#8F8F8F",
        },
        globalBgColor: {
          DEFAULT: "#F9F9F9",
        },
        colorBgSecondary: {
          DEFAULT: "#FFFFFF",
        },

        gray: {
          DEFAULT: "#9DA3AA",
          50: "#FFFFFF",
          100: "#F5F5F5",
          200: "#DFE1E3",
          300: "#C9CCD0",
          400: "#B3B8BD",
          500: "#9DA3AA",
          600: "#7F8790",
          700: "#646B73",
          800: "#4A4F55",
          900: "#262626",
        },
        primery: {
          DEFAULT: "#13e768",
          50: "#381D03",
          100: "#6E3806",
          200: "#A35309",
          300: "#D86E0C",
          400: "#F38A29",
          500: "#F59F50",
          600: "#F7B476",
          700: "#F9C99D",
          800: "#FCDEC4",
          900: "#FDE9D7",
        },
      }),
});

export const themeSettings: any = (mode: string) => {
  const colors = tokens(mode);

  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            primary: {
              main: "#13e768",
            },
            background: {
              default: "#262626",
            },
            text: {
              primary: "#fff",
            },
          }
        : {
            primary: {
              main: "#13e768",
            },
            background: {
              default: "#F8F8F8",
            },
            text: {
              primary: "#262626",
            },
          }),
    },
    typography: {
      fontFamily: [
        `${direction === "rtl" ? "Noto Sans Hebrew" : "Nunito Sans"}`,
        "sans-serif",
        "-apple-system",
      ].join(","),
    },
  };
};

export const ColorModeContext: any = createContext({
  toggleColorMode: () => {
    return true;
  },
});

export const useMode = () => {
  const [mode, setMode] = useState("dark");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => (prev === "light" ? "dark" : "light")),
    }),
    []
  );

  const theme: any = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return [theme, colorMode];
};
