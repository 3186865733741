import { Alert, Box, Button, TextField, useTheme } from "@mui/material";
import { Send } from "@mui/icons-material";
import "./form.scss";
import { tokens } from "../../theme/theme";
import { CustomTextField } from "../../models/inputs";
import { useForm, SubmitHandler } from "react-hook-form";
import { IShippingFields } from "./types";
import axios from "axios";

export const FormTelegram = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IShippingFields>({
    mode: "onChange",
  });

  const TOKEN = "6007165784:AAFk4ukKWuzeLfaTkeFUYXQgDmTJ8sdn1fQ";
  const CHAT_ID = "-1001806450701";
  const URI_API = `https://api.telegram.org/bot${TOKEN}/sendMessage`;

  const onSubmit: SubmitHandler<IShippingFields> = (data) => {
    let message = `<b>Заявка с сайта</b>\n`;
    message += `<b>Имя:</b> ${data.name} \n`;
    message += `<b>Телефон:</b> ${data.phone} \n`;
    message += `<b>Почта:</b> ${data.email} \n`;
    message += `<b>Сообщения:</b> ${data.msg}`;

    axios
      .post(URI_API, {
        chat_id: CHAT_ID,
        parse_mode: "html",
        text: message,
      })
      .then((res) => {
        reset();
      })
      .catch((err) => {
        console.warn(err);
      })
      .finally(() => {
        console.log("Конец");
      });
  };

  return (
    <Box
      component="form"
      className="form"
      sx={{
        border: `1px solid ${colors.borderColor.DEFAULT}`,
        backgroundColor: colors.bgColor.DEFAULT,
        color: colors.gray.DEFAULT,
        p: "20px",
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box className="form__group">
        <CustomTextField
          fullWidth
          label="Name"
          {...register("name", {
            required: "Обязательное поле",
            minLength: {
              value: 2,
              message: "Не меньше 2 символов",
            },
          })}
        />
        {errors?.name && (
          <Alert
            severity="error"
            sx={{ background: colors.globalBgColor.DEFAULT }}
          >
            {errors?.name.message}
          </Alert>
        )}
      </Box>
      <Box className="form__group">
        <TextField
          fullWidth
          label="Phone"
          {...register("phone", {
            required: "Обязательное поле",
            pattern: {
              value: /^[\d\+][\d\(\)\ -]{4,14}\d$/,
              message: "Введите валидный телефон",
            },
          })}
        />
        {errors?.phone && (
          <Alert
            severity="error"
            sx={{ background: colors.globalBgColor.DEFAULT }}
          >
            {errors?.phone.message}
          </Alert>
        )}
      </Box>
      <Box className="form__group">
        <TextField
          fullWidth
          label="Email"
          {...register("email", {
            required: "Обязательное поле",
            pattern: {
              value:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: "Введите валидный email",
            },
          })}
        />
        {errors?.email && (
          <Alert
            severity="error"
            sx={{ background: colors.globalBgColor.DEFAULT }}
          >
            {errors?.email.message}
          </Alert>
        )}
      </Box>
      <Box className="form__group">
        <TextField
          fullWidth
          label="Message"
          multiline
          rows={4}
          {...register("msg")}
        />
      </Box>
      <Box className="form__group">
        <Button
          type="submit"
          variant="outlined"
          endIcon={<Send />}
          className="btn"
        >
          Send
        </Button>
      </Box>
    </Box>
  );
};
