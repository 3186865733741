import { useState } from "react";
import { TopBar } from "../components/topBar/topBar";
import { Box } from "@mui/material";
import "./dashboardHome.scss";
import { SideBar } from "../components/sideBar/sideBar";
import { Outlet } from "react-router-dom";
import { Footer } from "../components/footer";

export const DashboardHome = () => {
  const [openMenu, setOpenMenu] = useState(true);

  return (
    <Box className="dashboardHome">
      <SideBar openMenu={openMenu} setOpenMenu={setOpenMenu} />
      <Box className="wrapper">
        <TopBar openMenu={openMenu} setOpenMenu={setOpenMenu} />
        <Box className="content" sx={{ flex: 1 }}>
          <Outlet />
        </Box>
        <Footer />
      </Box>
    </Box>
  );
};
