import { Avatar, Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme/theme";
import "./card.scss";

const cardInfo = {
  imgUrl: "assets/img/alex.jpg",
  alt: "Александр Буинцев",
  name: "Александр Буинцев",
  nickname: "AlexMerf",
  description: "Frontend разработчик, UX developer, ",
};

export const Card = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box className="Card" sx={{ background: colors.bgColor.DEFAULT }}>
      <Avatar
        src={cardInfo.imgUrl}
        alt={cardInfo.alt}
        className="avatar"
        sx={{ background: colors.primery.DEFAULT }}
      />
      <Box>
        <Typography component="span">{cardInfo.name}</Typography>
        <Typography>{cardInfo.description}</Typography>
      </Box>
    </Box>
  );
};
