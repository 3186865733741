import {
  IconButton,
  ImageListItem,
  ImageListItemBar,
  Tooltip,
  useTheme,
} from "@mui/material";
import { Info } from "@mui/icons-material";
import "./project.scss";
import { tokens } from "../../theme/theme";

const categoryList = [
  "Создание сайта",
  "Верстка",
  "Доработка сайта",
  "Pet проект",
];

interface IProject {
  category: number;
  description: string;
  id: number;
  imgUrl: string;
  link: string;
  rating: number;
}

export const Project = ({
  category,
  description,
  id,
  imgUrl,
  link,
  rating,
}: IProject) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <ImageListItem
      className="project"
      sx={{
        border: `1px solid ${colors.borderColor.DEFAULT}`,
        backgroundColor: colors.bgColor.DEFAULT,
        color: colors.gray.DEFAULT,
      }}
    >
      <img
        src={`${imgUrl}?w=248&fit=crop&auto=format`}
        srcSet={`${imgUrl}?w=248&fit=crop&auto=format&dpr=2 2x`}
        alt={description}
        loading="lazy"
        style={{ minHeight: "200px" }}
      />
      <ImageListItemBar
        title={description}
        subtitle={categoryList[category]}
        sx={{
          backgroundColor: colors.background.DEFAULT,
          "& .MuiImageListItemBar-titleWrap": {
            color: colors.gray[900],
          },
        }}
        actionIcon={
          <Tooltip title="на проект" arrow>
            <IconButton
              aria-label={`info about ${description}`}
              component="a"
              href={link}
            >
              <Info />
            </IconButton>
          </Tooltip>
        }
      />
    </ImageListItem>
  );
};
