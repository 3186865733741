import { useLocation, useRoutes } from "react-router-dom";
import { useEffect } from "react";
import { Box, ThemeProvider, CssBaseline } from "@mui/material";
import { ColorModeContext, useMode } from "./theme/theme";
import { DashboardHome } from "./layouts/dashboardHome";
import { Home } from "./pages/home";
import { Projects } from "./pages/projects";
import { Contacts } from "./pages/contacts";

function App() {
  const routes = useRoutes([
    {
      path: "",
      element: <DashboardHome />,
      children: [
        { path: "", element: <Home /> },
        { path: "projects", element: <Projects /> },
        { path: "contacts", element: <Contacts /> },
      ],
    },
  ]);

  let location = useLocation();

  let pathName =
    location.pathname.replace("/", "").charAt(0).toUpperCase() +
    location.pathname.replace("/", "").slice(1);

  function Example() {
    useEffect(() => {
      document.title = `${pathName} ${
        location.pathname === "/" ? "" : "|"
      } Alex Merf`;
    }, [location]);
  }

  Example();

  const [theme, colorMode] = useMode();

  return (
    <Box>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {routes}
        </ThemeProvider>
      </ColorModeContext.Provider>
    </Box>
  );
}

export default App;
