import { ImageList, useMediaQuery } from "@mui/material";
import { useState, useEffect } from "react";
import { Project } from "../../components/project";
import { Sceleton } from "../../components/sceleton";

export const Projects = () => {
  const [mockApi, setMockApi] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const matchesSx = useMediaQuery("(max-width:991px)");
  const matchesSt = useMediaQuery("(max-width:1300px)");

  useEffect(() => {
    fetch("https://634b9323317dc96a3087066e.mockapi.io/items")
      .then((res) => res.json())
      .then((json) => {
        setMockApi(json);
        setIsLoading(false);
      });
  }, []);

  return (
    <ImageList
      sx={{ width: "100%", height: "100%" }}
      variant="masonry"
      cols={matchesSx === false ? (matchesSt === true ? 2 : 3) : 1}
      gap={20}
    >
      {isLoading
        ? [...new Array(6)].map((_, index) => <Sceleton key={index} />)
        : mockApi.map((obj) => (
            <Project
              key={obj.id}
              category={obj.category}
              description={obj.description}
              id={obj.id}
              imgUrl={obj.imgUrl}
              link={obj.link}
              rating={obj.rating}
            />
          ))}
    </ImageList>
  );
};
