import { Box } from "@mui/material";
import { ContactsList } from "../../components/contactsList";
import { FormTelegram } from "../../components/formTelegram";
import "./contacts.scss"

export const Contacts = () => {
  return (
    <Box className="contacts__main">
      <FormTelegram />
      <ContactsList />
    </Box>
  );
};
