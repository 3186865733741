import { Box, Link, Typography } from "@mui/material";
import "./footer.scss";

export const Footer = () => {
  const data = new Date().getFullYear();

  return (
    <Box className="footer">
      <Typography component="span">&copy; 2019 - {data} </Typography>
      <Link href="https://alexmerf.store/">Merf Food</Link>
    </Box>
  );
};
