import { useContext, useState } from "react";
import {
  IconButton,
  useTheme,
  Box,
  BottomNavigation,
  BottomNavigationAction,
  useMediaQuery,
} from "@mui/material";
import { LightMode, DarkMode, ArrowBackIosNew } from "@mui/icons-material";
import { ColorModeContext, tokens } from "../../theme/theme";
import "./topBar.scss";
import { NavLink, useLocation } from "react-router-dom";

const navLink = [
  { id: 1, name: "Home", path: "/" },
  { id: 2, name: "Projects", path: "projects" },
  { id: 3, name: "Contacts", path: "contacts" },
];

const navPathNames = ["/", "/projects", "/contacts"];

export const TopBar = ({
  openMenu,
  setOpenMenu,
}: {
  openMenu: boolean;
  setOpenMenu: (event: boolean) => void;
}) => {
  let location = useLocation();
  const [value, setValue] = useState(navPathNames.indexOf(location.pathname));
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode: any = useContext(ColorModeContext);
  const matches = useMediaQuery("(max-width:768px)");

  return (
    <Box
      component="header"
      className="header top__bar"
      sx={{
        backgroundColor: colors.bgColor.DEFAULT,
        borderBottom: `1px solid ${colors.borderColor.DEFAULT}`,
      }}
    >
      <Box
        sx={{
          pl: `${matches === true && (openMenu === true ? "0px" : "220px")}`,
          transition: "padding-left .4s",
        }}
      >
        <IconButton
          aria-label="toogle menu"
          onClick={() => setOpenMenu(!openMenu)}
          className="toogle__menu"
        >
          <ArrowBackIosNew
            sx={{
              transition: "transform .2s",
              transform: `rotate(${
                matches === true
                  ? openMenu === true
                    ? "180deg"
                    : "0deg"
                  : openMenu === true
                  ? "0deg"
                  : "180deg"
              })`,
            }}
          />
        </IconButton>
      </Box>
      <Box className="header__box">
        {matches === false && (
          <BottomNavigation
            component="nav"
            showLabels
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            sx={{
              backgroundColor: colors.bgColor.DEFAULT,
            }}
          >
            {navLink.map((obj) => (
              <BottomNavigationAction
                key={obj.id}
                label={obj.name}
                component={NavLink}
                to={obj.path}
                sx={{ color: colors.gray[900] }}
              />
            ))}
          </BottomNavigation>
        )}

        <IconButton
          aria-label="theme toggle"
          onClick={colorMode.toggleColorMode}
        >
          {theme.palette.mode === "dark" ? <DarkMode /> : <LightMode />}
        </IconButton>
      </Box>
    </Box>
  );
};
